import React, { useContext, useEffect } from 'react'
import Head from 'next/head'
import { NextSeo } from 'next-seo'

import useFacebook from 'Clutch/Hooks/useFacebook'

import { useUserStore } from "Clutch/Stores/UserStore/UserStore";
import { ProductPageContext } from '../../contexts/ProductPageContext'


const HeadContent = ({ }) => {
  const productPageContext = useContext(ProductPageContext)
  const dynamicProductPageContext = useContext(ProductPageContext)
  const hydrated = useUserStore((x) => x.context.hydrated);
  const isBot = useUserStore((x) => x.context.isBot);
  const userGuid = useUserStore((x) => x.context.userGuid);
  const acquisitionTypeHydrated = useUserStore((x) => x.context.acquisitionTypeHydrated);
  const zipCode = useUserStore((x) => x.context.zipCode);

  const sendFacebookEvent = useFacebook()

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dispatchEvent(new CustomEvent("clarityautomate.initialized"));
    }
  });

  useEffect(() => {
    if (hydrated) {
      if (!isBot()) {
        dynamicProductPageContext.bustStaticPage(sendFacebookEvent)
      }

      if (!isBot() && userGuid && dynamicProductPageContext.skuVariantNumber) {
        dynamicProductPageContext.getLastPurchasedDateForSku(userGuid, dynamicProductPageContext.skuVariantNumber)
      }
    }
  }, [hydrated, userGuid , acquisitionTypeHydrated])

  useEffect(() => {

    if (hydrated && !isBot()) {
      if (dynamicProductPageContext.skuVariantNumber) {
        const zipCodeValidated = zipCode && !isNaN(zipCode) ? zipCode : '68528'
        dynamicProductPageContext.getShippingEstimate(dynamicProductPageContext.skuVariantNumber, zipCodeValidated)
      }
    }

  }, [dynamicProductPageContext.skuVariantNumber, hydrated, zipCode])

  return (
    <>
      <NextSeo
        title={productPageContext.title}
        description={productPageContext.metaDescription}
        canonical={(productPageContext.canonicalUrl || '').length ? productPageContext.canonicalUrl : null}
        noindex={false}
        nofollow={false}
        openGraph={{
          url: productPageContext.absoluteProductPageUrl,
          title: productPageContext.title,
          description: productPageContext.strippedInfoTab,
          images: [
            {
              url: (productPageContext.images || []).length ? `https:${productPageContext.images[0]}` : '',
              width: productPageContext.imageSize,
              height: productPageContext.imageSize,
              alt: productPageContext.title
            }
          ],
          site_name: 'Speedway Motors',
          type: 'product'
        }}
        twitter={{
          site: '@SpeedwayMotors',
          cardType: 'summary',
        }}
        additionalMetaTags={[{
          name: 'viewport',
          content: 'width=device-width,minimum-scale=1,initial-scale=1'
        }, {
          name: 'keywords',
          content: productPageContext.metaKeyword
        }, {
          name: 'language',
          content: 'english'
        }, {
          name: 'format-detection',
          content: 'telephone=no'
        }]}
        additionalLinkTags={[
          {
            rel: 'shortcut icon',
            href: '/favicon.ico',
          }, {
            rel: 'icon',
            href: '/favicon.ico',
            sizes: '152x152'
          }
        ]}
      />
    </>

  )
}

export default HeadContent
